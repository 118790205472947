import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import RebelFencingCrest from '../../images/RebelFencingCrest.png';

import styles from './footer.module.scss';

const Footer = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <a
          href="https://www.facebook.com/RebelFencingClub/"
          target="_blank"
          className={styles.iconWrapper}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <div className={styles.image}>
          <img
            alt="RebelFencingLogo"
            src={RebelFencingCrest}
            height={width > 767 ? '64px' : '32px'}
          />
        </div>
        <a
          href="https://www.instagram.com/rebelfencingclub/"
          target="_blank"
          className={styles.iconWrapper}
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
