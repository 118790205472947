import {
  defaultTheme,
  mergeTheme,
  Table,
  ThemeProvider,
  Card,
} from 'evergreen-ui';
import React from 'react';
import Faq from 'react-faq-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import FoilImage from '../../images/foil.png';
import GroupPhoto from '../../images/groupPhoto.jpg';
import GroupPhoto1 from '../../images/groupPhoto1.jpg';
import GroupPhoto2 from '../../images/groupPhoto2.jpg';
import styles from './home.module.scss';

const theme = mergeTheme(defaultTheme, {
  components: {
    TableHead: {
      baseStyle: {
        color: 'black',
        backgroundColor: 'rgba(163,0,0,0.15)',
      },
    },
  },
});
const data = {
  title: '',
  rows: [
    {
      title: 'What is Rebel Fencing?',
      content: (
        <div>
          <p>
            Rebel Fencing is a foil only fencing club based in Cork
            City. We train one evening a week and are open to both new
            and experienced fencers. The club provides all the
            necessary equipment.
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'What is fencing?',
      content: (
        <div>
          <p>
            Olympic fencing is a form of sword fighting. Two people
            face each other and use modern swords to score points by
            hitting a target area. There are three different
            disciplines in fencing: foil, saber, and epee. Each weapon
            differs in the equipment needed and there are variations
            in the rules, but the essence is the same between all
            three, the goal is to score a hit on your opponent with
            your weapon while avoiding being hit yourself.
          </p>
          <p>Watch the video below to learn more.</p>
          <Card display="flex" justifyContent="center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/g-P_q5gdvRM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Card>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'Should I try fencing?',
      content: (
        <div>
          <p>
            Yes! Fencing is a fantastic sport for all ages and
            abilities. There are many benefits associated with
            fencing, including:
          </p>
          <ul>
            <li>
              Improves speed, agility, flexibility and reflexes.
            </li>
            <li>Boosts mental strength and concentration.</li>
            <li>Fencing is a great way to get and/or stay fit.</li>
            <li>
              The sport is that it can be continued throughout life.
            </li>
            <li>And fencing is a lot of fun!</li>
          </ul>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'Do I need previous experience to join Rebel Fencing?',
      content: (
        <div>
          <p>No. We are open to both new and experienced fencers.</p>
          <p>
            We run an 8 week beginner course where those new to
            fencing will learn all the basics of fencing. After
            completing the course, you can choose to become a full
            club member and continue to improve.
          </p>
          <p>
            Those with previous experience fencing can join the main
            training group.
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'Can children or teenagers join?',
      content: (
        <div>
          <p>
            No. We are an adult only club. All members must be 18 or
            older.
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'Do I need any equipment?',
      content: (
        <div>
          <p>No. We will provide all the necessary equipment.</p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'What should I wear?',
      content: (
        <div>
          <p>
            Whatever you would wear for other forms of exercise;
            stretchy and comfy clothing you can move in, and runners.
            Long trousers are recommended. No jeans or hard soled
            shoes.
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'When and where do you train?',
      content: (
        <div>
          <p>
            We train every Wednesday 6-8pm in James Kenneally Sports
            Hall, Hollyhill Ln, Gurranabraher, Cork.
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="45rem" />
          </div>
        </div>
      ),
    },
    {
      title: 'How much does it cost?',
      content: (
        <div>
          <p>
            All Rebel Fencing members <b>must</b> also hold a fencing
            licence from our governing body, Fencing Ireland. Your
            first licence is free. Details can be found on the{' '}
            <span className={styles.linkWrapper}>
              <a
                href="https://www.fencingireland.net/membership/"
                target="_blank"
                rel="noreferrer"
              >
                Fencing Ireland website.
              </a>
            </span>
          </p>
          <ThemeProvider value={theme}>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>
                  Rebel Fencing Membership Fees
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Head>
                <Table.TextHeaderCell backgroundColor="red100">
                  Yearly
                </Table.TextHeaderCell>
                <Table.TextHeaderCell intent="danger">
                  Autumn Term
                </Table.TextHeaderCell>
                <Table.TextHeaderCell intent="danger">
                  Spring Term
                </Table.TextHeaderCell>
                <Table.TextHeaderCell intent="danger">
                  Summer Term
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.TextCell>€440</Table.TextCell>
                  <Table.TextCell>€154</Table.TextCell>
                  <Table.TextCell>€176</Table.TextCell>
                  <Table.TextCell>€110</Table.TextCell>
                </Table.Row>
              </Table.Body>
              <Table.Head>
                <Table.TextHeaderCell>
                  Beginner Course - 8 weeks
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.TextCell>€110</Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </ThemeProvider>
          <p>
            If you are interested in joining Rebel Fencing,
            participating in our next beginner course or have any
            questions, please email{' '}
            <span className={styles.linkWrapper}>
              <a href="mailto:admin@rebelfencing.ie&subject=Membership Enquiry">
                admin@rebelfencing.ie
              </a>
            </span>
          </p>
          <div className={styles.faqImage}>
            <img alt="FoilImage" src={FoilImage} height="75px" />
          </div>
        </div>
      ),
    },
  ],
};

const faqStyles = {
  titleTextSize: '24px',
};

const Home = () => {
  const { width } = useWindowDimensions();
  return (
    <div id="top" className={styles.home}>
      <h1>Welcome to Rebel Fencing Club</h1>
      <p>
        Rebel Fencing is a newly established fencing club based in
        Cork City. We cater to adults who want to start or to continue
        their training in the Olympic sport of fencing. The club
        provides all the necessary equipment and beginner courses are
        run regularly for those new to the sport.{' '}
      </p>
      <Card>
        {width > 767 && <img alt="GroupPhoto1" src={GroupPhoto1} height="200px" />}
        <img alt="GroupPhoto" src={GroupPhoto} height="200px" />
        {width > 767 && <img alt="GroupPhoto2" src={GroupPhoto2} height="200px" />}
      </Card>
      <div id="about" className={styles.faqWrapper}>
        <h3 className={styles.sectionTitle}>About</h3>
        <Faq
          data={data}
          styles={faqStyles}
          // config={config}
        />
      </div>
      <div id="timetable" className={styles.timetable}>
        <h3 className={styles.sectionTitle}>Training Times</h3>
        <p>We currently train once a week, on Wednesdays 6-8pm.</p>
        <p>
          Training takes place in James Kenneally Sports Hall,
          Hollyhill Ln, Gurranabraher, Cork.
        </p>
      </div>
      <div id="contact-us" className={styles.contact}>
        <h3 className={styles.sectionTitle}>Contact us</h3>
        <p>
          Please contact us at:{' '}
          <span className={styles.linkWrapper}>
            <a href="mailto:admin@rebelfencing.ie&subject=Website Enquiry">
              admin@rebelfencing.ie
            </a>
          </span>
        </p>
        <p>
          <span className={`${styles.linkWrapper} ${styles.social}`}>
            <a
              href="https://www.facebook.com/RebelFencingClub/"
              target="_blank"
              className={styles.iconWrapper}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </span>
          <span className={`${styles.linkWrapper} ${styles.social}`}>
            <a
              href="https://www.instagram.com/rebelfencingclub/"
              target="_blank"
              className={styles.iconWrapper}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </span>
        </p>

        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.6899716544012!2d-8.506124684224558!3d51.903121679702096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48449159e9ca3199%3A0xa9a7fce900840c1!2sJames%20Kenneally%20Sports%20Hall!5e0!3m2!1sen!2sie!4v1660240338955!5m2!1sen!2sie"
          style={{ border: '0' }}
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
