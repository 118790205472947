import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import style from './App.module.scss';

function App() {
  return (
    <div className={style.main}>
      <Header />
      <div className={style.mainContent}>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
