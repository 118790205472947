import React from 'react';
import { HashLink } from 'react-router-hash-link';

import Crest from '../../images/RebelFencingCrest.png';
import styles from './header.module.scss';

const MenuLink = (props) => (
  <HashLink className={styles.menuItem} smooth to={props.to}>
    {props.children}
  </HashLink>
);

const Header = () => {
  return (
    <div className={styles.main}>
      <div className={styles.crestContainer}>
        <img src={Crest} alt="RebelFencingCrest" />
      </div>
      <div className={styles.header}>
        <span className={styles.title}>Rebel Fencing Club</span>
        <div className={styles.menu}>
          <MenuLink to="/#top">Home</MenuLink>
          <MenuLink to="/#about">About</MenuLink>
          <MenuLink to="/#timetable">Timetable</MenuLink>
          <MenuLink to="/#contact-us">Contact</MenuLink>
        </div>
      </div>
    </div>
  );
};

export default Header;
